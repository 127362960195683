import React from "react"

import { Layout, HeaderBorder } from "../components/layouts/Layout"
import { Section } from "../components/elements"
import {
  Grid,
  ButtonA,
  Col,
  AlignCenter,
  Margin,
} from "../components/utilities"

const DisclosuresPage = () => (
  <Layout title="Disclosures">
    <Section>
      <h1>Disclosures</h1>

      <HeaderBorder maxWidth="400px" />
      <Margin modifiers={["mb4"]} />

      <AlignCenter>
        <Grid modifiers={["gap"]}>
          <Col>
            <ButtonA
              href="/funds-availability-policy.pdf"
              modifiers={["primaryOutline"]}
            >
              FUNDS AVAILABLE POLICY
            </ButtonA>
          </Col>
          <Col>
            <ButtonA
              href="/membership-account-agreement.pdf"
              modifiers={["primaryOutline"]}
            >
              MEMBERSHIP AND ACOUNT AGREEMENT
            </ButtonA>
          </Col>
          <Col>
            <ButtonA href="/privacy-notice.pdf" modifiers={["primaryOutline"]}>
              PRIVACY NOTICE
            </ButtonA>
          </Col>
          <Col>
            <ButtonA
              href="/safcu-fee-schedule.pdf"
              modifiers={["primaryOutline"]}
            >
              Safcu Fee Schedule
            </ButtonA>
          </Col>
          <Col>
            <ButtonA
              href="/electronic-funds.pdf"
              modifiers={["primaryOutline"]}
            >
              ELECTRONIC FUNDS TRANSFER
            </ButtonA>
          </Col>
          <Col>
            <ButtonA href="/" modifiers={["primaryOutline"]}>
              CONSUMER CREDIT CARD AGREEMENT
            </ButtonA>
          </Col>
          <Col>
            <ButtonA
              href="/courtesy-pay-program-disclosure.pdf"
              modifiers={["primaryOutline"]}
            >
              COURTESY PAY PROGRAM DISCLOSURE
            </ButtonA>
          </Col>
          <Col>
            <ButtonA
              href="/div-cert-rate-sheet.pdf"
              modifiers={["primaryOutline"]}
            >
              DIVIDEND RATE TABLE
            </ButtonA>
          </Col>
          <Col>
            <ButtonA href="/checking-policy.pdf" modifiers={["primaryOutline"]}>
              CHECKING POLICY FOR MEMBERS
            </ButtonA>
          </Col>
        </Grid>
      </AlignCenter>
    </Section>
  </Layout>
)

export default DisclosuresPage
